import './experience.css';
import React from 'react';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
const Experience = () => {
    return (
        <section id={'experience'}>
            <h5>What Skills I have </h5>
            <h2>My Experience</h2>
            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Backend Experience</h3>
                    <div className="experience__content">
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Java</h4>
                                <small className={'text-dark'}>Experienced</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Python</h4>
                                <small className={'text-dark'}>Experienced</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>NodeJS</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Amazon Neptune</h4>
                                <small className={'text-dark'}>Experienced</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>MySQL</h4>
                                <small className={'text-dark'}>Experienced</small>
                            </div>
                        </article>
                    </div>
                </div>
                <div className="experience__frontend">
                    <h3>Frontend Experience</h3>
                    <div className="experience__content">
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>HTML</h4>
                                <small className={'text-dark'}>Experienced</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Javascript / Typescript</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>React</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>CSS</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>
                    </div>
                </div>
                <div className="experience__frontend">
                    <h3>Technologies</h3>
                    <div className="experience__content">
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Performance Engineering</h4>
                                <small className={'text-dark'}>Experienced</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>AWS</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Apache Tinkerpop Gremlin</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Logs & Metric Collection</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Machine Learning</h4>
                                <small className={'text-dark'}>Basic</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Android</h4>
                                <small className={'text-dark'}>Basic</small>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="experience__frontend">
                    <h3>Tools & Frameworks</h3>
                    <div className="experience__content">
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Spring</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Intellij</h4>
                                <small className={'text-dark'}>Experienced</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>CI/CD</h4>
                                <small className={'text-dark'}>Intermediate</small>
                            </div>
                        </article>
                        <article className={'experience__details'}>
                            <VerifiedOutlinedIcon className={'experience__details-icon'}/>
                            <div>
                                <h4>Jira</h4>
                                <small className={'text-dark'}>Experienced</small>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Experience;
