import React, {useState} from 'react';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import './nav.css';
const Nav = () => {

    const [activeNav, setActiveNav] = useState('#');

    return (
        <nav>
            <a href={"#"} onClick={()=> setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}> <OtherHousesOutlinedIcon/> </a>
            <a href={"#about"} onClick={()=> setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''} > <PersonOutlineIcon/> </a>
            <a href={"#experience"} onClick={()=> setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}> <AutoStoriesIcon/> </a>
            <a href={"#contact"} onClick={()=> setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}> <TextsmsOutlinedIcon/> </a>
        </nav>
    );
};

export default Nav;
