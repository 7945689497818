import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
const HeaderSocials = () => {
    return (
        <div className={'header__socials'}>
            <a href="https://www.linkedin.com/in/rajendra-prasad-arukala-56a3a71a/" target={'_blank'}><LinkedInIcon/></a>
            <a href="https://www.facebook.com/rajendra.arukala" target={'_blank'}><FacebookIcon/></a>
            <a href="https://www.instagram.com/arprasad/" target={'_blank'}><InstagramIcon/></a>
        </div>
    );
};

export default HeaderSocials;
