import React from 'react';
import './css/App.css';
import Header from "./components/header/header";
import Nav from "./components/nav/Nav";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Experience from "./components/experience/experience";
import Services from "./components/services/services";
function App() {
    return (
        <div className="App">
            <Header/>
            <Nav/>
            <About/>
            <Experience/>
            <Contact/>
        </div>
    );
}

export default App;
