import './contact.css';

import React from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import {Input, InputLabel, TextareaAutosize} from "@mui/material";
const Contact = () => {
    return (
        <section id={'contact'}>
            <h5> Get in touch</h5>
            <h2> Contact Me</h2>

            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <EmailOutlinedIcon className={'contact__option-icon'}/>
                        <h4>Email</h4>
                        <h5>rajendra.arukala@outlook.in</h5>
                        <a href="mailto:rajendra.arukala@outlook.in" target={'_blank'}>Send a message</a>
                    </article>
                    <article className="contact__option">
                        <ChatBubbleOutlineOutlinedIcon className={'contact__option-icon'}/>
                        <h4>Messenger</h4>
                        <h5>rajendra.arukala</h5>
                        <a href="https://m.me/rajendra.arukala" target={'_blank'}>Send a message</a>
                    </article>
                </div>
                <form action={""}>
                    <input type={'text'} placeholder={'Your name'} required={true} disabled={true}/>
                    <input type={'text'} placeholder={'Your email'} required={true} disabled={true}/>
                    <textarea placeholder={'Your message'} rows={7} required={true} disabled={true}/>
                    <button type={'submit'} className={'btn btn-primary'} disabled={true}> Send Message</button>
                </form>
            </div>
        </section>
    );
};

export default Contact;
