import React from 'react';
import './about.css'
import ME from '../../assets/pp2.jpg'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
const About = () => {
    return (
        <section id={"about"}>
            <h5>Get to Know</h5>
            <h2>About Me</h2>
            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ME} alt={""}/>
                    </div>
                </div>
                <div className="about__content">
                    <div className="about__cards">
                        <article className={'about__card'}>
                            <EmojiEventsOutlinedIcon className={'about__icon'}/>
                            <h5>Experience</h5>
                            <small>10+yrs</small>
                        </article>
                        <article className={'about__card'}>
                            <KeyboardAltOutlinedIcon className={'about__icon'}/>
                            <h5>Projects</h5>
                            <small>12+</small>
                        </article>
                    </div>
                    <p>
                        Principal Software Engineer with 10 years of working experience in the field of Computer Software industry.
                        Skilled in Java, Python, NodeJS, design and development of software applications, Graph databases, systems and frameworks. Well versed with OO Programming principles, design patterns, functional programming. Additional hands on experience with Data Science and Machine Learning.

                    </p>
                    <p>
                        Presently working for Infinite Blue.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;
