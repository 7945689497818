import React from 'react';
import CTA from "./CTA";
import ME from '../../assets/pp.png'
import HeaderSocials from "./headerSocials";
import './header.css'
const Header = () => {
    return (
        <header>
            <div className="container">
                <div className="container header__container">
                    <h3>Hello, I'm </h3>
                    <h1>Rajendra Prasad Arukala</h1>
                    <h3 className="text-dark">Principal Software Engineer (Backend) </h3>
                </div>
            </div>
            <CTA/>
            <HeaderSocials/>
            <div className="me">
                <img src={ME} alt={"me"}/>
            </div>
            <a href="#contact" className={'scroll__down'}>Scroll Down</a>

        </header>
    );
};

export default Header;
